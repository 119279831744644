<template>
    <div class="grid-2 flex-center">
        <section class="upload--container">
            <h2 class="title">
                <span class="title-label">{{ $t('uploadTitle') }}</span>
                <span class="upload--limit" v-if="maxSizeAllowed > 0">
                    <i18n path="uploadLimit">
                        <span place="limit">{{ maxSizeAllowed / 1073741824 }}</span>
                    </i18n>
                </span>
                <span class="upload--limit retention">
                    {{ $t('fileRetentionLimit') }}
                </span>
            </h2>
            <div v-bind:class="status" class="upload--widget-container">
                <!--Chunks limited to 50 MB ; size of uploaded file limited to 50 GB-->
                <dx-file-uploader name="formFile"
                                  accept="*"
                                  :upload-mode="uploadMode"
                                  :upload-url="fileUploaderUrl"
                                  :multiple="true"
                                  :max-file-size="maxSizeAllowed"
                                  :chunk-size="getMaxChunkSize"
                                  :ref="fileUploaderRefName"
                                  @uploaded="uploaded"
                                  @uploadAborted="uploadAborted"
                                  @valueChanged="valueChanged"
                                  @upload-started="uploadStart" />
                <p role="status" aria-live="polite" v-if="zipMessage" class="zip--message badge warning">{{ $t('uploadZipMesg') }}</p>
                <p role="status" aria-live="polite" v-if="maxSizeAllowedError" class="badge danger">
                    <i18n path="maxSizeAllowedErrorMesg">
                        <span place="limit">{{ maxSizeAllowed / 1073741824 }}</span>
                    </i18n>
                </p>
                <button v-if="showReset" class="button reset--button" @click="resetUpload">Effectuer un nouvel envoi</button>
            </div>
            <div v-if="fileUrl" class="upload--result">
                <p role="status" aria-live="polite" v-if="uploadAsError" class="badge danger">{{ $t('uploadErrorMesg') }}</p>
                <p role="status" aria-live="polite" v-if="uploadSuccess" class="badge success">{{ $t('uploadSucessMesg') }}</p>
                <label for="fileUrl">{{ $t('uploadLabelInstruction') }}</label>
                <textarea id="fileUrl" type="text" class="upload--url" :value="fileUrl" @click="copy('fileUrl', 'link')" readonly></textarea>
                <div class="copy--container">
                    <button class="copy--button button" id="copyLink" @click="copy('fileUrl', 'link')">{{ $t('copyLinkBtn') }}</button>
                    <i class="badge warning anim--up" v-if="linkWarning">{{ $t('copyLinkWarning') }}</i>
                </div>
                <label for="fileCode">{{ $t('uploadCodeInstruction') }}</label>
                <input id="fileCode" type="text" class="upload--code" :value="fileCode" @click="copy('fileCode', 'code')" readonly />
                <div class="copy--container">
                    <button class="copy--button button" id="copyLink" @click="copy('fileCode', 'code')">{{ $t('copyCodeBtn') }}</button>
                    <i class="badge warning anim--up" v-if="codeWarning">{{ $t('copyCodeWarning') }}</i>
                </div>
            </div>
        </section>
        <section class="download--container">
            <h2 class="title">
                <span class="title-label">{{ $t('downloadTitle') }}</span>
            </h2>
            <label class="dowload--label" for="download--code">{{ $t('downloadInstruction') }}</label>
            <input id="download--code" class="download--code" type="text" v-model="getCode" />
            <p role="status" aria-live="polite" v-if="showError" class="badge danger">{{ $t('downloadMesg') }}</p>
            <a class="button sr-only" v-if="showDownloadLink" id="click" :href="getUrl" download>{{ $t('downloadBtn') }}</a>
            <button v-bind:disabled="btnDownloadStatusDisabled" v-bind:class="btnDownloadStatus" class="download--button button" v-if="getCode" @click="getFile">{{ $t('downloadBtn') }}</button>
        </section>
    </div>
</template>

<script type="text/javascript">
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import { DxButton } from 'devextreme-vue';
    import { LoadingMethods } from '@/Common/Mixins/loading-panel-communication';
    import Axios from '../Vue/Common/http-service';

    const fileUploaderRefName = "some-ref-name";

    export default {
        mixins: [
            LoadingMethods
        ],
        components: {
            DxFileUploader,
            DxButton
        },
        data() {
            return {
                fileUploaderRefName,
                uploadMode: 'useButtons',
                files: [],
                numberFiles: 0,
                isMulti: false,
                codeWarning: false,
                showReset: false,
                fileUrl: '',
                fileCode: '',
                getCode: '',
                getUrl: '',
                linkWarning: false,
                uploadActive: false,
                uploadAsError: false,
                uploadSuccess: false,
                showDownloadLink: false,
                showError: false,
                status: 'ready',
                zipMessage: false,
                btnDownloadStatus: '',
                btnDownloadStatusDisabled: false,
                maxSizeAllowed: 0,
                maxChunkSize: 0,
                useChunkUploadSize: 0,
                maxSizeAllowedError: false,
                useChunkUpload : false
            }
        },
        methods: {
            resetUpload() {
                this.showError = false;
                this.uploadAsError = false;
                this.status = 'ready';
                this.showReset = false;
                this.isMulti = false;
                this.fileUrl = '';
                this.files = [];
                this.fileUploader.reset();
            },
            uploaded(data) {
                var json = JSON.parse(data.request.response);
                this.files.push(json.fileName);
                if (this.isMulti && (this.files.length == this.numberFiles)) {
                    this.showLoadingPanel();
                    this.zipMessage = true;
                    Axios.post('Transfer/Zip', this.files).then(response => {
                        this.uploadAsError = false;
                        this.printCode(response.data);
                        this.zipMessage = false;
                        this.hideLoadingPanel();
                        return;
                    }).catch(error => {
                        this.zipMessage = false;
                        this.hideLoadingPanel();
                        this.uploadError()
                    });
                } else if (!this.isMulti) {
                    this.printCode(json);
                }
            },
            printCode(result) {
                window.onbeforeunload = false;
                this.showReset = true;
                this.fileUrl = encodeURI(result.fileUrl);
                this.fileCode = result.fileCode;
                this.status = 'uploaded';
                this.uploadSuccess = true;
                this.$nextTick(function () {
                    if (this.fileUrl) {
                        var selectText = document.getElementById('fileUrl');
                        selectText.select();
                    }
                });
            },
            valueChanged(e) {
                var totalSize = 0;

                e.value.forEach(function (elem) {
                    totalSize += elem.size;
                });

                if (totalSize > this.useChunkUploadSize)
                    this.useChunkUpload = true;
                else
                    this.useChunkUpload = false;

                if (totalSize >= this.maxSizeAllowed) {
                    this.maxSizeAllowedError = true;
                    this.status = 'on--maxSizeAllowed';
                }
                else {
                    this.maxSizeAllowedError = false;
                    this.status = 'ready';
                }

                if (e.value.length > 1) {
                    this.isMulti = true;
                } else {
                    this.isMulti = false;
                }
                this.numberFiles = e.value.length;

                if ((e.value.length == 0) && (this.status != 'ready')) {
                    this.resetUpload()
                }
            },
            uploadError() {
                this.uploadAsError = true;
                window.onbeforeunload = false;
                this.status = 'uploaded';
                this.status = 'ready';
                this.hideLoadingPanel();
            },
            uploadStart() {
                this.status = 'on--upload';
                window.onbeforeunload = function () {
                    return "Your work will be lost.";
                };
            },
            uploadAborted() {
                window.onbeforeunload = false;
                this.status = 'ready';
            },
            copy(element, warning) {
                var selectText = document.getElementById(element);
                selectText.select();
                document.execCommand('copy');

                if (warning == 'link') {
                    this.linkWarning = true
                    window.setTimeout(() => {
                        this.linkWarning = false;
                    }, 1500);
                };

                if (warning == 'code') {
                    this.codeWarning = true
                    window.setTimeout(() => {
                        this.codeWarning = false;
                    }, 1500);
                };
            },
            selectLink(event) {
                var selectText = event.target;
                selectText.select();
                document.execCommand('copy');
            },
            getFile() {
                this.btnDownloadStatus = 'disabled';
                this.btnDownloadStatusDisabled = true;
                Axios.get('Transfer/Download/Code/Verify/' + this.getCode).then(response => {
                    console.log(response.data);
                    if (response.data == true) {
                        this.showError = false;
                        this.showDownloadLink = true;
                        this.$nextTick(function () {
                            var link = document.getElementById("click");
                            link.click();
                            window.setTimeout(() => {
                                this.btnDownloadStatus = '';
                                this.btnDownloadStatusDisabled = false;
                            }, 1500);
                        });
                    } else {
                        this.showDownloadLink = false;
                        this.showError = true;
                        this.btnDownloadStatus = '';
                        this.btnDownloadStatusDisabled = false;
                    }
                    window.setTimeout(() => {
                        this.btnDownloadStatus = '';
                        this.btnDownloadStatusDisabled = false;
                    }, 1500);
                }).catch(error => {
                    this.showDownloadLink = false;
                    this.showError = true;
                    this.btnDownloadStatus = '';
                });
            },
            getValueInKb(value) {
                return `${(value / 1024).toFixed(0)}kb`;
            }
        },
        async mounted() {

            await Axios.get('Transfer/GetConfiguration').then(response => {
                this.maxSizeAllowed = response.data.maxSizeAllowed;
                this.maxChunkSize = response.data.maxChunkSize;
                this.useChunkUploadSize = response.data.useChunkUploadSize;
            });

            new Promise((resolve, reject) => {
                window.setTimeout(() => {
                    resolve(this.hideLoadingPanel())
                }, 300);
            });
        },
        created() {
            this.showLoadingPanel();
            if (localStorage.locale) {
                this.$i18n.locale = localStorage.locale;
            }
        },
        watch: {
            getCode: function (value) {
                var vm = this;
                vm.getUrl = Axios.getUrl('Transfer/Download/Code/' + vm.getCode);
            }
        },
        computed: {
            fileUploader: function () {
                return this.$refs[fileUploaderRefName].instance;
            },
            fileUploaderUrl: function () {
                return this.useChunkUpload ? Axios.getUrl('Transfer/UploadWithChunk') : Axios.getUrl('Transfer/UploadWithoutChunk');
            },
            getMaxChunkSize: function () {
                return this.useChunkUpload ? this.maxChunkSize : 0;
            }
        }
    }

</script>

<style>
</style>

<i18n>
    {
    "fr": {
    "uploadTitle": "Envoi de fichiers",
    "uploadLimit": "Maximum {limit}Go par envoi",
    "fileRetentionLimit": "Durée de rétention : 2 mois",
    "uploadSucessMesg": "Fichiers réceptionnés avec succès !",
    "uploadErrorMesg": "Une erreur s'est produite veuillez essayer de nouveau.",
    "uploadLabelInstruction": "Ils peuvent être téléchargés en accédant à cette adresse :",
    "uploadCodeInstruction": "Ou en saisissant ce code dans la section télécharger un fichier",
    "uploadZipMesg": "L'archive est en cours de création. Veuillez patientier.",
    "copyCodeBtn": "Copier le code",
    "copyLinkBtn": "Copier le lien",
    "copyCodeWarning": "Le code a été copié dans le presse-papier",
    "copyLinkWarning": "Le lien a été copié dans le presse-papier",
    "downloadTitle": "Téléchargement de fichiers",
    "downloadBtn": "Télécharger",
    "downloadInstruction": "Saisissez votre code :",
    "downloadMesg": "Impossible de trouver le fichier correspondant.",
    "maxSizeAllowedErrorMesg": "La taille totale dépasse les {limit}Go"
    },
    "en": {
    "uploadTitle": "Upload files",
    "uploadLimit": "Maximum {limit}GB per upload",
    "fileRetentionLimit": "Retention : 2 months",
    "uploadErrorMesg": "An error occurred please try again.",
    "uploadSucessMesg": "Files successfully uploaded!",
    "uploadLabelInstruction": "They can be downloaded by accessing this url:",
    "uploadCodeInstruction": "Or by using this code in the download file section:",
    "uploadZipMesg": "Please wait. The archive is being created.",
    "copyCodeBtn": "Copy code",
    "copyLinkBtn": "Copy link",
    "copyCodeWarning": "The code was copied to the clipboard",
    "copyLinkWarning": "The link was copied to the clipboard",
    "downloadTitle": "Download files",
    "downloadBtn": "Download",
    "downloadInstruction": "Please enter your code:",
    "downloadMesg": "The requested file doesn't exists.",
    "maxSizeAllowedErrorMesg": "The total size amount exceed {limit}GB"
    }
    }
</i18n>