import { render, staticRenderFns } from "./home.vue?vue&type=template&id=3070a514&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./home.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("E:\\NetProjects\\FileProvisionService\\FileProvisionService\\TransferUI\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('3070a514', component.options)
    } else {
      api.reload('3070a514', component.options)
    }
    module.hot.accept("./home.vue?vue&type=template&id=3070a514&", function () {
      api.rerender('3070a514', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Vue/home.vue"
export default component.exports