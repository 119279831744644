import axios from 'axios';

// URL for production
const baseUrl = `https://telechargement.inao.gouv.fr/telechargementApi/api/v1/`
// URL test production local
// const baseUrl = `http://telechargement.inao.gouv.fr/telechargementApi/api/v1/`
// URL for dev
// const baseUrl = `${window.location.protocol}//${window.location.host}/telechargementApi/api/v1/`
const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.request.use((config) => {
    
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(config), 1000);
    });
}, (error) => {
    
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
    
    return response;
}, (error) => {
    
    return Promise.reject(error);
    });

axiosInstance.getUrl = function (url) {
    let fullUrl = `${baseUrl}${url}`;

    return fullUrl;
};

export default axiosInstance;