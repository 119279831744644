<template>
    <div class="grid-2 flex-center">
        <section class="upload--container">
            <h2 class="title">
                {{ $t('fileNotFoundTitle') }}
            </h2>
            <div class="upload--widget-container upload--result">
                <p role="status" aria-live="polite" class="badge danger">
                    {{$t('fileNotFoundMessage')}}
                </p>
                <button class="copy--button button" id="copyLink" @click="goToHome">{{ $t('goToHome') }}</button>
            </div>
           
        </section>
       
    </div>
</template>

<script type="text/javascript">   
    import { LoadingMethods } from '@/Common/Mixins/loading-panel-communication';

    export default {
        mixins: [
            LoadingMethods
        ],
        methods: {
            goToHome() {
                this.$router.push({ name: 'Home' });
            }
        },
        mounted() {
            new Promise((resolve, reject) => {
                window.setTimeout(() => {
                    resolve(this.hideLoadingPanel())
                }, 300);
            });
        },
        created() {
          
            if (localStorage.locale) {
                this.$i18n.locale = localStorage.locale;
            }
        }
    }

</script>

<style>
</style>

<i18n>
    {
    "fr": {
    "fileNotFoundTitle": "Fichier indisponible",
    "fileNotFoundMessage": "Le fichier demandé n'est plus disponible",
    "goToHome": "Revenir à l'accueil"
    },
    "en": {
    "fileNotFoundTitle": "File not found",
    "fileNotFoundMessage": "The requested file is not available anymore",
    "goToHome": "Go to the homepage"
    }
    }
</i18n>
